import { Survey } from "survey-react-ui";
import { useNavigate, useLocation } from "react-router-dom";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "survey-core/survey.i18n";
import { useTranslation } from 'react-i18next';

// Import survey templates
import preSurveyJsonPt from "../../surveyTemplates/Pt/preSurveyFormPt";
import postSurveyFormPt from "../../surveyTemplates/Pt/postSurveyFormPt";
import preSurveyJsonHindi from "../../surveyTemplates/Hi/preSurveyFormHindi";
import postSurveyFormHindi from "../../surveyTemplates/Hi/postSurveyFormHindi";
import preSurveyJsonEn from "../../surveyTemplates/En/preSurveyFormEn";
import postSurveyFormEn from "../../surveyTemplates/En/postSurveyFormEn";
import preSurveyJsonEnx from "../../surveyTemplates/Enx/preSurveyFormEnx";
import postSurveyFormEnx from "../../surveyTemplates/Enx/postSurveyFormEnx";
import preSurveyJsonEs from "../../surveyTemplates/Es/preSurveyFormEs";
import postSurveyFormEs from "../../surveyTemplates/Es/postSurveyFormEs";
import preSurveyJsonPtx from "../../surveyTemplates/Ptx/preSurveyFormPtx";
import postSurveyFormPtx from "../../surveyTemplates/Ptx/postSurveyFormPtx";
import preSurveyJsonSp from "../../surveyTemplates/Sp/preSurveyFormSp";
import postSurveyFormSp from "../../surveyTemplates/Sp/postSurveyFormSp";
// import preSurveyJsonTl from "../../surveyTemplates/Tl/preSurveyFormTl";
// import postSurveyFormTl from "../../surveyTemplates/Tl/postSurveyFormTl";

// Import the JSON files for Telugu survey
import data2 from "../../surveyTemplates/Tl/data2.json";
import participantData from "../../surveyTemplates/Tl/participants.json";

const SurveyPage = () => {
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const { state } = location;
    const checked_users = state.checkedUsers;
    const unchecked_users = state.uncheckedUsers;
    const formType = state.formType;

    const saveSurveyToServer = (data) => {
        console.log(`saving ${data} to server..`);

        axiosPrivate
            .post("/save-survey", data)
            .then((res) => {
                if (res.data === "SurveySaved") {
                    console.log("Form Saved to server!");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    let surveyJson = {};
    if (formType === 0) {
        switch (i18n.language) {
            case 'en':
                surveyJson = preSurveyJsonEn();
                break;
            case 'enx':
                surveyJson = preSurveyJsonEnx();
                break;
            case 'pt':
                surveyJson = preSurveyJsonPt();
                break;
            case 'ptx':
                surveyJson = preSurveyJsonPtx();
                break;
            case 'hi':
                surveyJson = preSurveyJsonHindi();
                break;
            case 'es':
                surveyJson = preSurveyJsonEs();
                break;
            case 'sp':
                surveyJson = preSurveyJsonSp();
                break;
            case 'tl':
                surveyJson = data2; // Use data2.json for the Telugu pre-survey
                break;
            default:
                surveyJson = preSurveyJsonEn();
                break;
        }
    } else {
        switch (i18n.language) {
            case 'en':
                surveyJson = postSurveyFormEn(unchecked_users, checked_users);
                break;
            case 'enx':
                surveyJson = postSurveyFormEnx(unchecked_users, checked_users);
                break;
            case 'pt':
                surveyJson = postSurveyFormPt(unchecked_users, checked_users);
                break;
            case 'ptx':
                surveyJson = postSurveyFormPtx(unchecked_users, checked_users);
                break;
            case 'hi':
                surveyJson = postSurveyFormHindi(unchecked_users, checked_users);
                break;
            case 'es':
                surveyJson = postSurveyFormEs(unchecked_users, checked_users);
                break;
            case 'sp':
                surveyJson = postSurveyFormSp(unchecked_users, checked_users);
                break;
            // case 'tl':
            //     surveyJson = data2; // Use data2.json for the Telugu post-survey
            //     break;
            default:
                surveyJson = postSurveyFormEn(unchecked_users, checked_users);
                break;
        }
    }

    const survey = new Model(surveyJson);

    if (i18n.language === 'tl') {
        // Value change handler for 'interview_time' and 'participant_id'
        const handleValueChanged = (sender, options) => {
            if (options.name === "interview_time") {
                const timeSlot = options.value;
                const filteredParticipants = participantData.filter(
                    (p) => p.time_slot === timeSlot
                );

                const participantQuestion = survey.getQuestionByName("participant_id");
                participantQuestion.choices = [
                    ...filteredParticipants.map((p) => ({
                        value: p.name,
                        text: p.name,
                    })),
                    {
                        value: "NA",
                        text: "NA",
                    },
                ];
            }

            if (options.name === "participant_id") {
                const selectedName = options.value;
                const selectedParticipant = participantData.find(
                    (p) => p.name === selectedName
                );

                if (selectedParticipant) {
                    survey.setValue("phone_number", selectedParticipant.phone_number);
                }
            }
        };

        // Add the onValueChanged event handler
        survey.onValueChanged.add(handleValueChanged);

        // Instantiate Showdown
        const converter = new showdown.Converter();

        survey.onTextMarkdown.add((survey, options) => {
            if (options.text.includes("{phone_number}")) {
                const phoneNumber = survey.getValue("phone_number");
                let text = options.text.replace(
                    "{phone_number}",
                    phoneNumber || "___________"
                );
                // Convert Markdown to HTML
                let html = converter.makeHtml(text);
                // Remove root paragraphs <p></p>
                html = html.substring(3, html.length - 4);
                // Set HTML markup to render
                options.html = html;
            } else {
                // Existing Markdown handling
                let str = converter.makeHtml(options.text);
                str = str.substring(3, str.length - 4);
                options.html = str;
            }
        });
    } else {
        // Existing Markdown handler
        const converter = new showdown.Converter();
        survey.onTextMarkdown.add(function (survey, options) {
            // Convert Markdown to HTML
            let str = converter.makeHtml(options.text);
            // Remove root paragraphs <p></p>
            str = str.substring(3, str.length - 4);
            // Set HTML markup to render
            options.html = str;
        });
    }

    survey.onComplete.add((sender, options) => {
        let surveyInfo = {
            info: {
                clientId: state.clientId,
                clientName: state.clientName,
                surveyType: state.formType,
                timestamp: new Date(),
            },
        };
        let data = { ...surveyInfo, ...sender.data };
        console.log(data);
        saveSurveyToServer(data);

        setTimeout(() => {
            if (formType === 0) {
                let stateData = {
                    username: state.data.username,
                    village: state.data.village,
                    tehsilOrBlock: state.data.tehsilOrBlock,
                    email: state.data.email,
                    waNo: state.data.waNo,
                    bio: state.data.bio,
                    userId: state.data.userId,
                };
                navigate("/addUser", { state: { from: location, data: stateData }, replace: true });
            } else {
                navigate("/allUsers", { state: { from: location }, replace: true });
            }
        }, 2000);
    });

    survey.locale = i18n.language;

    return <Survey model={survey} />;
};

export default SurveyPage;
