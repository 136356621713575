export default function postSurveyFormEnx(unchecked_list, checked_list) {
    let result = [];

    // Set 1
    result.push({
        name: 'page1',
        elements: [
            {
                type: 'radiogroup',
                name: 'eligible_to_vote',
                title: 'Are you eligible to vote in the 2024 US elections?',
                isRequired: true,
                choices: [
                    'Yes',
                    'No',
                    'Unsure'
                ]
            }
        ]
    });

    // Question 2, shown only if 'Yes' is selected in the previous question
    result.push({
        name: 'page2',
        visibleIf: "{eligible_to_vote} = 'Yes'",
        elements: [
            {
                type: 'radiogroup',
                name: 'political_party_support',
                title: 'Which political party do you currently support for the 2024 US elections?',
                isRequired: true,
                choices: [
                    'Democratic Party',
                    'Republican Party',
                    'Independent',
                    'Undecided'
                ],
                showOtherItem: true
            }
        ]
    });

    // Set 2
    result.push({
        name: 'page3',
        elements: [
            {
                type: 'radiogroup',
                name: 'whatsapp_usage_frequency',
                title: 'How frequently do you use WhatsApp?',
                isRequired: true,
                choices: [
                    'Several times a day',
                    'Once a day',
                    'A few times a week',
                    'Once a week',
                    'Rarely',
                    'Never'
                ]
            }
        ]
    });

    result.push({
        name: 'page4',
        elements: [
            {
                type: 'checkbox',
                name: 'whatsapp_primary_uses',
                title: 'What are your primary uses of WhatsApp? (Select all that apply)',
                isRequired: true,
                choices: [
                    'Chatting with friends and family',
                    'Group discussions',
                    'Sharing media (photos, videos, links)',
                    'Business communication',
                    'Political discussions'
                ],
                showOtherItem: true
            }
        ]
    });

    result.push({
        name: 'page5',
        elements: [
            {
                type: 'radiogroup',
                name: 'political_discussions_frequency',
                title: 'How often do you participate in political discussions on WhatsApp?',
                isRequired: true,
                choices: [
                    'Very often',
                    'Sometimes',
                    'Rarely',
                    'Never'
                ]
            }
        ]
    });

    // Set 3
    result.push({
        name: 'page6',
        elements: [
            {
                type: 'radiogroup',
                name: 'encountered_misinfo',
                title: 'Have you ever come across messages on WhatsApp that you believe contain false or misleading information?',
                isRequired: true,
                choices: [
                    'Yes',
                    'No',
                    'Unsure'
                ]
            }
        ]
    });

    // Subsequent questions shown only if 'Yes' is selected
    result.push({
        name: 'page7',
        visibleIf: "{encountered_misinfo} = 'Yes'",
        elements: [
            {
                type: 'radiogroup',
                name: 'misinfo_frequency',
                title: 'How frequently do you encounter content that you think is misinformation on WhatsApp?',
                isRequired: true,
                choices: [
                    'Several times a day',
                    'Once a day',
                    'A few times a week',
                    'Once a week',
                    'Rarely',
                    'Never'
                ]
            }
        ]
    });

    result.push({
        name: 'page8',
        visibleIf: "{encountered_misinfo} = 'Yes'",
        elements: [
            {
                type: 'checkbox',
                name: 'misinfo_types',
                title: 'What types of misinformation have you encountered on WhatsApp? (Select all that apply)',
                isRequired: true,
                choices: [
                    'Political propaganda',
                    'Health-related misinformation',
                    'Financial scams',
                    'False news about celebrities or public figures',
                    'Social issues (e.g., immigration, race relations)'
                ],
                showOtherItem: true
            }
        ]
    });

    result.push({
        name: 'page9',
        visibleIf: "{encountered_misinfo} = 'Yes'",
        elements: [
            {
                type: 'checkbox',
                name: 'misinfo_response',
                title: 'What do you usually do when you come across misinformation on WhatsApp? (Select all that apply)',
                isRequired: true,
                choices: [
                    'Ignore it',
                    'Fact-check it using external sources',
                    'Share it with others',
                    'Confront or correct the person who shared it',
                    'Report it to WhatsApp or the group admin'
                ],
                showOtherItem: true
            }
        ]
    });

    result.push({
        name: 'page10',
        elements: [
            {
                type: 'radiogroup',
                name: 'misinfo_influence',
                title: 'Do you believe political propaganda on WhatsApp influences people\'s opinions and actions?',
                isRequired: true,
                choices: [
                    'Yes, significantly',
                    'Yes, somewhat',
                    'No',
                    'Unsure'
                ]
            }
        ]
    });

    // Build the survey form object
    let surveyForm = {
        progressBarType: 'pages',
        showProgressBar: 'top',
        logoPosition: 'right',
        pages: result
    };

    return JSON.stringify(surveyForm);
}
