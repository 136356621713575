export default function preSurveyJsonSp() {
    let result = [
        {
            name: "post-survey-page-1",
            elements: [{
                "type": "text",
                "name": "age",
                "title": "¿Cuál es su edad?",
                "isRequired": true,
                "inputType": "number",
                "min": 0,
                "max": 100,
                "minErrorText": "Rango de entrada: 0 - 100",
                "maxErrorText": "Rango de entrada: 0 - 100"
            },
            {
                "type": "radiogroup",
                "name": "gender",
                "title": "¿Es usted…?",
                "isRequired": true,
                "choices": [
                    "Hombre",
                    "Mujer",
                    "Otro"
                ]
            }
            ]
        },
        {
            name: "post-survey-page-2",
            elements: [{
                "type": "checkbox",
                "name": "race",
                "title": "¿Cuál es su raza/etnia? (Seleccione todas las que correspondan)",
                "isRequired": true,
                "choices": [
                    "Blanco o de ascendencia europea",
                    "Afrodescendiente o Afroamericano",
                    "Hispano o Latino",
                    "Asiático",
                    "Nativo Americano o Nativo de Alaska",
                    "Nativo de Hawái u otra isla del Pacífico",
                    "Raza mixta",
                    "Prefiero no decir",
                    "Otra (Por favor especifique)"
                ]
            },
            {
                "type": "radiogroup",
                "name": "religion",
                "title": "¿Cuál es su afiliación religiosa? (Seleccione una)",
                "isRequired": true,
                "choices": [
                    "Católico",
                    "Cristiano evangélico",
                    "Cristiano (No evangélico)",
                    "Creyente sin religión específica",
                    "Agnóstico",
                    "Ateo",
                    "Otra religión (Por favor especifique)",
                    "Prefiero no decir"
                ]
            }
            ]
        },
        {
            name: "post-survey-page-3",
            visibleIf: "{race} contains 'Hispano o Latino'",
            elements: [{
                "type": "checkbox",
                "name": "hispanicOrigin",
                "title": "¿Cuál es su origen específico hispano, latino o latinoamericano? (Seleccione todas las que correspondan)",
                "isRequired": true,
                "choices": [
                    "Argentino",
                    "Boliviano",
                    "Brasileño",
                    "Chileno",
                    "Colombiano",
                    "Costarricense",
                    "Cubano",
                    "Dominicano",
                    "Ecuatoriano",
                    "Guatemalteco",
                    "Guyanés",
                    "Haitiano",
                    "Hondureño",
                    "Jamaiquino",
                    "Mexicano, mexicano-americano o chicano",
                    "Nicaragüense",
                    "Panameño",
                    "Paraguayo",
                    "Peruano",
                    "Puertorriqueño",
                    "Salvadoreño",
                    "Español",
                    "Surinamés",
                    "Uruguayo",
                    "Venezolano",
                    "Otro centroamericano (especificar)",
                    "Otro sudamericano (especificar)",
                    "Otro caribeño (especificar)",
                    "Otro hispano o latino (especificar)"
                ]
            }]
        },
        {
            name: "post-survey-page-4",
            elements: [{
                "type": "radiogroup",
                "name": "education",
                "title": "¿Cuál es su máximo nivel educativo alcanzado?",
                "isRequired": true,
                "choices": [
                    "Escuela primaria",
                    "Escuela secundaria",
                    "Educación superior (post-secundario)",
                    "Otro"
                ]
            },
            {
                "type": "text",
                "name": "zip",
                "title": "¿Cuál es su código postal de residencia en EE.UU.?",
                "isRequired": true,
                "inputType": "text",
                "validators": [
                    {
                        "type": "regex",
                        "text": "Por favor, ingrese un código postal válido de 5 dígitos o en formato ZIP+4.",
                        "regex": "^\\d{5}(-\\d{4})?$"
                    }
                ]
            }            
            ]
        },
        {
            name: "post-survey-page-5",
            elements: [{
                "type": "radiogroup",
                "name": "Monthly Income",
                "title": "¿Cuál es el ingreso mensual de su familia?",
                "isRequired": true,
                "choices": [
                    "Menos de $20,000",
                    "$20,000 - $39,999",
                    "$40,000 - $69,999",
                    "$70,000 - $119,999",
                    "$120,000 o más"
                ]
            }
            ]
        }
    ];

    let preSurveyForm = {
        "progressBarType": "pages",
        "showProgressBar": "top",
        logoPosition: "right",
        pages: result
    };
    return JSON.stringify(preSurveyForm);
}
