export default function postSurveyFormPtx(unchecked_list, checked_list) {
    let result = [];

    // Conjunto 1
    result.push({
        name: 'page1',
        elements: [
            {
                type: 'radiogroup',
                name: 'eligible_to_vote',
                title: 'Você tem direito a votar nas eleições dos EUA de 2024?',
                isRequired: true,
                choices: [
                    'Sim',
                    'Não',
                    'Não tenho certeza'
                ]
            }
        ]
    });

    // Pergunta 2, exibida somente se 'Sim' for selecionado na pergunta anterior
    result.push({
        name: 'page2',
        visibleIf: "{eligible_to_vote} = 'Sim'",
        elements: [
            {
                type: 'radiogroup',
                name: 'political_party_support',
                title: 'Qual partido político você atualmente apoia para as eleições dos EUA de 2024?',
                isRequired: true,
                choices: [
                    'Partido Democrata',
                    'Partido Republicano',
                    'Independente',
                    'Indeciso'
                ],
                showOtherItem: true
            }
        ]
    });

    // Conjunto 2
    result.push({
        name: 'page3',
        elements: [
            {
                type: 'radiogroup',
                name: 'whatsapp_usage_frequency',
                title: 'Com que frequência você usa o WhatsApp?',
                isRequired: true,
                choices: [
                    'Várias vezes ao dia',
                    'Uma vez ao dia',
                    'Algumas vezes por semana',
                    'Uma vez por semana',
                    'Raramente',
                    'Nunca'
                ]
            }
        ]
    });

    result.push({
        name: 'page4',
        elements: [
            {
                type: 'checkbox',
                name: 'whatsapp_primary_uses',
                title: 'Quais são os seus usos principais do WhatsApp? (Selecione todas as opções que se aplicam)',
                isRequired: true,
                choices: [
                    'Conversar com amigos e familiares',
                    'Discussões em grupo',
                    'Compartilhar mídia (fotos, vídeos, links)',
                    'Comunicação empresarial',
                    'Discussões políticas'
                ],
                showOtherItem: true
            }
        ]
    });

    result.push({
        name: 'page5',
        elements: [
            {
                type: 'radiogroup',
                name: 'political_discussions_frequency',
                title: 'Com que frequência você participa de discussões políticas no WhatsApp?',
                isRequired: true,
                choices: [
                    'Muito frequentemente',
                    'Às vezes',
                    'Raramente',
                    'Nunca'
                ]
            }
        ]
    });

    // Conjunto 3
    result.push({
        name: 'page6',
        elements: [
            {
                type: 'radiogroup',
                name: 'encountered_misinfo',
                title: 'Você já se deparou com mensagens no WhatsApp que acredita conter informações falsas ou enganosas?',
                isRequired: true,
                choices: [
                    'Sim',
                    'Não',
                    'Não tenho certeza'
                ]
            }
        ]
    });

    // Perguntas subsequentes exibidas somente se 'Sim' for selecionado
    result.push({
        name: 'page7',
        visibleIf: "{encountered_misinfo} = 'Sim'",
        elements: [
            {
                type: 'radiogroup',
                name: 'misinfo_frequency',
                title: 'Com que frequência você encontra conteúdo que você acha que é desinformação no WhatsApp?',
                isRequired: true,
                choices: [
                    'Várias vezes ao dia',
                    'Uma vez ao dia',
                    'Algumas vezes por semana',
                    'Uma vez por semana',
                    'Raramente',
                    'Nunca'
                ]
            }
        ]
    });

    result.push({
        name: 'page8',
        visibleIf: "{encountered_misinfo} = 'Sim'",
        elements: [
            {
                type: 'checkbox',
                name: 'misinfo_types',
                title: 'Que tipos de desinformação você encontrou no WhatsApp? (Selecione todas as opções que se aplicam)',
                isRequired: true,
                choices: [
                    'Propaganda política',
                    'Desinformação relacionada à saúde',
                    'Golpes financeiros',
                    'Notícias falsas sobre celebridades ou figuras públicas',
                    'Questões sociais (por exemplo, imigração, relações raciais)'
                ],
                showOtherItem: true
            }
        ]
    });

    result.push({
        name: 'page9',
        visibleIf: "{encountered_misinfo} = 'Sim'",
        elements: [
            {
                type: 'checkbox',
                name: 'misinfo_response',
                title: 'O que você geralmente faz quando encontra desinformação no WhatsApp? (Selecione todas as opções que se aplicam)',
                isRequired: true,
                choices: [
                    'Ignorar',
                    'Verificar os fatos usando fontes externas',
                    'Compartilhar com outros',
                    'Confrontar ou corrigir a pessoa que compartilhou',
                    'Reportar ao WhatsApp ou ao administrador do grupo'
                ],
                showOtherItem: true
            }
        ]
    });

    result.push({
        name: 'page10',
        elements: [
            {
                type: 'radiogroup',
                name: 'misinfo_influence',
                title: 'Você acredita que a propaganda política no WhatsApp influencia as opiniões e ações das pessoas?',
                isRequired: true,
                choices: [
                    'Sim, significativamente',
                    'Sim, de certa forma',
                    'Não',
                    'Não tenho certeza'
                ]
            }
        ]
    });

    // Construir o objeto do formulário de pesquisa
    let surveyForm = {
        progressBarType: 'pages',
        showProgressBar: 'top',
        logoPosition: 'right',
        pages: result
    };

    return JSON.stringify(surveyForm);
}
