export default function postSurveyFormSp(unchecked_list, checked_list) {
    let result = [];

    // Set 1
    result.push({
        name: 'page1',
        elements: [
            {
                type: 'radiogroup',
                name: 'eligible_to_vote',
                title: '¿Está habilitado para votar en las elecciones de EE. UU. de 2024?',
                isRequired: true,
                choices: [
                    'Sí',
                    'No',
                    'No estoy seguro/a'
                ]
            }
        ]
    });

    // Question 2, shown only if 'Yes' is selected in the previous question
    result.push({
        name: 'page2',
        visibleIf: "{eligible_to_vote} = 'Sí'",
        elements: [
            {
                type: 'radiogroup',
                name: 'political_party_support',
                title: '¿Qué partido político apoya actualmente para las elecciones de EE. UU. de 2024?',
                isRequired: true,
                choices: [
                    'Partido Demócrata',
                    'Partido Republicano',
                    'Independiente',
                    'Indeciso/a'
                ],
                showOtherItem: true
            }
        ]
    });

    // Set 2
    result.push({
        name: 'page3',
        elements: [
            {
                type: 'radiogroup',
                name: 'whatsapp_usage_frequency',
                title: '¿Qué tan frecuentemente usa WhatsApp?',
                isRequired: true,
                choices: [
                    'Varias veces al día',
                    'Una vez al día',
                    'Unas pocas veces a la semana',
                    'Una vez a la semana',
                    'Raramente',
                    'Nunca'
                ]
            }
        ]
    });

    result.push({
        name: 'page4',
        elements: [
            {
                type: 'checkbox',
                name: 'whatsapp_primary_uses',
                title: '¿Cuáles son sus principales usos de WhatsApp? (Seleccione todos los que correspondan)',
                isRequired: true,
                choices: [
                    'Chatear con amigos y familia',
                    'Discusiones grupales con personas mayoritariamente desconocidas',
                    'Compartir contenido (fotos, videos, enlaces)',
                    'Comunicación laboral',
                    'Discusiones políticas'
                ],
                showOtherItem: true
            }
        ]
    });

    result.push({
        name: 'page5',
        elements: [
            {
                type: 'radiogroup',
                name: 'political_discussions_frequency',
                title: '¿Con qué frecuencia participa en discusiones políticas en WhatsApp?',
                isRequired: true,
                choices: [
                    'Frecuentemente',
                    'A veces',
                    'Raramente',
                    'Nunca'
                ]
            }
        ]
    });

    // Set 3
    result.push({
        name: 'page6',
        elements: [
            {
                type: 'radiogroup',
                name: 'encountered_misinfo',
                title: '¿Alguna vez se ha encontrado con mensajes en WhatsApp que cree que contienen información falsa o engañosa?',
                isRequired: true,
                choices: [
                    'Sí',
                    'No',
                    'No estoy seguro/a'
                ]
            }
        ]
    });

    // Subsequent questions shown only if 'Yes' is selected
    result.push({
        name: 'page7',
        visibleIf: "{encountered_misinfo} = 'Sí'",
        elements: [
            {
                type: 'radiogroup',
                name: 'misinfo_frequency',
                title: '¿Qué tan frecuentemente encuentra contenido en WhatsApp que cree que es desinformación?',
                isRequired: true,
                choices: [
                    'Varias veces al día',
                    'Una vez al día',
                    'Unas pocas veces a la semana',
                    'Una vez a la semana',
                    'Raramente',
                    'Nunca'
                ]
            }
        ]
    });

    result.push({
        name: 'page8',
        visibleIf: "{encountered_misinfo} = 'Sí'",
        elements: [
            {
                type: 'checkbox',
                name: 'misinfo_types',
                title: '¿Qué tipos de desinformación ha encontrado en WhatsApp? (Seleccione todas las que correspondan)',
                isRequired: true,
                choices: [
                    'Propaganda política',
                    'Desinformación relacionada con la salud',
                    'Estafas financieras',
                    'Noticias falsas sobre celebridades o figuras públicas',
                    'Temas sociales (por ejemplo, inmigración, cuestiones raciales)'
                ],
                showOtherItem: true
            }
        ]
    });

    result.push({
        name: 'page9',
        visibleIf: "{encountered_misinfo} = 'Sí'",
        elements: [
            {
                type: 'checkbox',
                name: 'misinfo_response',
                title: '¿Qué suele hacer cuando se encuentra con desinformación en WhatsApp? (Seleccione todas las que correspondan)',
                isRequired: true,
                choices: [
                    'Ignorarla',
                    'Verificarla utilizando fuentes externas',
                    'Compartirla con otros',
                    'Confrontar o corregir a la persona que la compartió',
                    'Reportarla a WhatsApp o al administrador del grupo'
                ],
                showOtherItem: true
            }
        ]
    });

    result.push({
        name: 'page10',
        elements: [
            {
                type: 'radiogroup',
                name: 'misinfo_influence',
                title: '¿Cree que la propaganda política en WhatsApp influye en las opiniones y acciones de las personas?',
                isRequired: true,
                choices: [
                    'Sí, significativamente',
                    'Sí, en cierta medida',
                    'No',
                    'No estoy seguro/a'
                ]
            }
        ]
    });

    // Build the survey form object
    let surveyForm = {
        progressBarType: 'pages',
        showProgressBar: 'top',
        logoPosition: 'right',
        pages: result
    };

    return JSON.stringify(surveyForm);
}