export default function preSurveyJsonEnx() {
    let result = [
        {
            name: "post-survey-page-1",
            elements: [
                {
                    "type": "text",
                    "name": "age",
                    "title": "What is your age?",
                    "isRequired": true,
                    "inputType": "number",
                    "min": 0,
                    "max": 100,
                    "minErrorText": "Input range: 0 - 100",
                    "maxErrorText": "Input range: 0 - 100"
                },
                {
                    "type": "radiogroup",
                    "name": "gender",
                    "title": "What is your gender?",
                    "isRequired": true,
                    "choices": [
                        "Male",
                        "Female"
                    ]
                }
            ]
        },
        {
            name: "post-survey-page-2",
            elements: [
                {
                    "type": "checkbox",
                    "name": "race_ethnicity",
                    "title": "What is your race/ethnicity? (Select all that apply)",
                    "isRequired": true,
                    "choices": [
                        "White",
                        "Black or African American",
                        "Hispanic or Latino",
                        "Asian",
                        "Native American or Alaska Native",
                        "Native Hawaiian or Other Pacific Islander",
                        "Mixed race",
                        "Prefer not to say",
                        "Other (Please specify)"
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "religious_affiliation",
                    "title": "What is your religious affiliation? (Select one)",
                    "isRequired": true,
                    "choices": [
                        "Catholic",
                        "Evangelical Christian",
                        "Christian (Non-Evangelical)",
                        "Believer with no specific religion",
                        "Agnostic",
                        "Atheist",
                        "Another religion (Please specify)",
                        "Prefer not to say"
                    ]
                }
            ]
        },
        {
            name: "post-survey-page-3",
            elements: [
                {
                    "type": "radiogroup",
                    "name": "education",
                    "title": "What is your level of education?",
                    "isRequired": true,
                    "choices": [
                        "Elementary School",
                        "High School",
                        "Higher Education",
                        "Other"
                    ]
                },
                {
                    "type": "text",
                    "name": "zip",
                    "title": "What is the ZIP code of your residence?",
                    "isRequired": true,
                    "inputType": "number",
                    "min": 10000,
                    "minErrorText": "Please enter a valid ZIP code with at least 5 digits."
                }
            ]
        },
        {
            name: "post-survey-page-4",
            elements: [
                {
                    "type": "radiogroup",
                    "name": "monthly_income",
                    "title": "What is your family's monthly income?",
                    "isRequired": true,
                    "choices": [
                        "$0-$2,000",
                        "$2,000-$3,000",
                        "$3,000-$5,000",
                        "$5,000-$10,000",
                        "Above $10,000"
                    ]
                }
                // Removed the "Resources" question about Bolsa Família
            ]
        }
    ];

    let preSurveyForm = {
        "progressBarType": "pages",
        "showProgressBar": "top",
        "logoPosition": "right",
        "pages": result
    };
    return JSON.stringify(preSurveyForm);
}
